var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            ["edit", "add", "addOrder", "addModuleOrder"].includes(
              _vm.$route.query.type
            )
              ? _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-form-inline",
                    attrs: {
                      "label-position": "right",
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-width": "135px"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "生产工单编号",
                                  prop: "productionPlanNumber"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "",
                                    disabled: [
                                      "edit",
                                      "view",
                                      "addOrder"
                                    ].includes(_vm.$route.query.type)
                                  },
                                  model: {
                                    value: _vm.ruleForm.productionPlanNumber,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "productionPlanNumber",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.productionPlanNumber"
                                  }
                                }),
                                ["add", "addModuleOrder"].includes(
                                  _vm.$route.query.type
                                )
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "22px",
                                          right: "0"
                                        },
                                        attrs: { type: "text" },
                                        on: { click: _vm.getCode }
                                      },
                                      [_vm._v("自动生成 ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "工单类型", prop: "type" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    on: { change: _vm.changeWorkType },
                                    model: {
                                      value: _vm.ruleForm.type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.ruleForm, "type", $$v)
                                      },
                                      expression: "ruleForm.type"
                                    }
                                  },
                                  _vm._l(_vm.workType, function(item) {
                                    return _c("el-option", {
                                      key: item.key,
                                      attrs: {
                                        label: item.name,
                                        value: item.key
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "产出物料",
                                  prop: "materialsId"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    directives: [
                                      {
                                        name: "selectScrollEnd",
                                        rawName: "v-selectScrollEnd",
                                        value: _vm.finishedMaterialsLoadMore,
                                        expression: "finishedMaterialsLoadMore"
                                      }
                                    ],
                                    attrs: {
                                      filterable: "",
                                      remote: "",
                                      "reserve-keyword": "",
                                      "remote-method": _vm.remoteFinishedMethod,
                                      placeholder: "请选择",
                                      disabled: [
                                        "edit",
                                        "addModuleOrder"
                                      ].includes(_vm.$route.query.type),
                                      clearable: ""
                                    },
                                    on: { change: _vm.selMaterial },
                                    model: {
                                      value: _vm.ruleForm.materialsId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "materialsId",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.materialsId"
                                    }
                                  },
                                  _vm._l(_vm.finishedMaterials, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.specifications
                                          ? item.fullLabel +
                                            " / " +
                                            item.specifications
                                          : item.fullLabel,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 6 } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-end",
                                position: "relative"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ruleForm.type == 1,
                                      expression: "ruleForm.type == 1"
                                    }
                                  ],
                                  staticStyle: {
                                    position: "absolute",
                                    left: "10px",
                                    right: "10px"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      disabled: ["edit"].includes(
                                        _vm.$route.query.type
                                      )
                                    },
                                    on: {
                                      change: _vm.handleSingleProcessChange
                                    },
                                    model: {
                                      value: _vm.isSingleProcess,
                                      callback: function($$v) {
                                        _vm.isSingleProcess = $$v
                                      },
                                      expression: "isSingleProcess"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "margin-top": "2px"
                                      }
                                    },
                                    [_vm._v("单工序")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  style: {
                                    width:
                                      _vm.ruleForm.type == 1 ? "95%" : "100%"
                                  }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "工艺路线",
                                        prop: _vm.isSingleProcess
                                          ? ""
                                          : "processFlowFullName"
                                      }
                                    },
                                    [
                                      _c("el-cascader", {
                                        ref: "cascader",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          options: _vm.processFlows,
                                          props: {
                                            lazy: true,
                                            lazyLoad: _vm.loadOptions,
                                            label: "processFlowFullName",
                                            value: "id"
                                          },
                                          disabled: _vm.isSingleProcess
                                        },
                                        on: { change: _vm.handleChange },
                                        model: {
                                          value:
                                            _vm.ruleForm.processFlowFullName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "processFlowFullName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.processFlowFullName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "工单产量", prop: "quantity" }
                              },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    "controls-position": "right",
                                    value: 1,
                                    min: 0,
                                    step: 1
                                  },
                                  on: {
                                    blur: function(e) {
                                      return _vm.formatNum(
                                        e,
                                        _vm.ruleForm,
                                        "quantity",
                                        5
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.ruleForm.quantity,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "quantity", $$v)
                                    },
                                    expression: "ruleForm.quantity"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "工单优先级", prop: "priority" }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.ruleForm.priority,
                                      callback: function($$v) {
                                        _vm.$set(_vm.ruleForm, "priority", $$v)
                                      },
                                      expression: "ruleForm.priority"
                                    }
                                  },
                                  _vm._l(_vm.priorityList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "项目生产节点",
                                  prop: "projectNode",
                                  rules: {
                                    required: _vm.isShow,
                                    message: "请选择项目生产节点",
                                    trigger: "blur"
                                  }
                                }
                              },
                              [
                                _c("el-cascader", {
                                  ref: "myCascader",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    options: _vm.projectList,
                                    clearable: "",
                                    props: {
                                      lazy: true,
                                      lazyLoad: _vm.loadProjectOptions,
                                      label: "projectName",
                                      value: "projectId"
                                    }
                                  },
                                  on: { change: _vm.selProject },
                                  model: {
                                    value: _vm.ruleForm.projectNode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "projectNode", $$v)
                                    },
                                    expression: "ruleForm.projectNode"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "负责人" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    directives: [
                                      {
                                        name: "selectScrollEnd",
                                        rawName: "v-selectScrollEnd",
                                        value: _vm.userLoadMore,
                                        expression: "userLoadMore"
                                      }
                                    ],
                                    attrs: {
                                      filterable: "",
                                      remote: "",
                                      "reserve-keyword": "",
                                      "remote-method": _vm.userRemoteMethod,
                                      placeholder: "请选择",
                                      clearable: "",
                                      multiple: "",
                                      "collapse-tags": ""
                                    },
                                    on: { change: _vm.selUser },
                                    model: {
                                      value: _vm.ruleForm.principalId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "principalId",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.principalId"
                                    }
                                  },
                                  _vm._l(_vm.userList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.userFullName,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ruleForm.type == 1,
                                expression: "ruleForm.type == 1"
                              }
                            ],
                            attrs: { span: 6 }
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "上阶工单" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    directives: [
                                      {
                                        name: "selectScrollEnd",
                                        rawName: "v-selectScrollEnd",
                                        value: _vm.moduleMaterialsLoadMore,
                                        expression: "moduleMaterialsLoadMore"
                                      }
                                    ],
                                    attrs: {
                                      filterable: "",
                                      remote: "",
                                      "reserve-keyword": "",
                                      "remote-method": _vm.remoteMethod,
                                      placeholder: "请选择",
                                      clearable: ""
                                    },
                                    on: { change: _vm.selUpWorkOrder },
                                    model: {
                                      value: _vm.ruleForm.upWorkOrderId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "upWorkOrderId",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.upWorkOrderId"
                                    }
                                  },
                                  _vm._l(_vm.moduleMaterials, function(item) {
                                    return _c("el-option", {
                                      key: item.producePlanId,
                                      attrs: {
                                        label: item.fullLabel,
                                        value: item.producePlanId
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "计划开工时间",
                                  prop: "planStartTime"
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "datetime",
                                    placeholder: "计划开工时间",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    format: "yyyy-MM-dd HH:mm:ss",
                                    "default-time": "00:00:00",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.ruleForm.planStartTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "planStartTime",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.planStartTime"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "计划完工时间",
                                  prop: "planEndTime"
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "datetime",
                                    placeholder: "计划完工时间",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    format: "yyyy-MM-dd HH:mm:ss",
                                    "default-time": "23:59:59",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.ruleForm.planEndTime,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "planEndTime", $$v)
                                    },
                                    expression: "ruleForm.planEndTime"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注：" } },
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea", rows: 1 },
                                  model: {
                                    value: _vm.ruleForm.remark,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "remark", $$v)
                                    },
                                    expression: "ruleForm.remark"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-form-inline",
                    attrs: {
                      "label-position": "right",
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-width": "138px"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "flex-flow": "row wrap" },
                        attrs: { type: "flex", justify: "start" }
                      },
                      _vm._l(_vm.detailBaseFields, function(item) {
                        return _c(
                          "el-col",
                          { key: item.key, attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: item.name } },
                              [
                                item.key === "materialsId"
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            _vm.viewMaterialsDetail(
                                              _vm.getDetailVal(item.key)
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailVal("materialsName")
                                          ) + " "
                                        )
                                      ]
                                    )
                                  : _c("div", [
                                      _vm._v(_vm._s(_vm.getDetailVal(item.key)))
                                    ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
          ],
          1
        )
      ]),
      _c(
        "el-tabs",
        {
          staticClass: "detail-card detail-tabs",
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.tabClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", { attrs: { label: "工艺路线", name: "1" } }, [
            _c("div", [
              _c("div", { staticClass: "detail-card" }, [
                _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
                _c(
                  "div",
                  { staticClass: "card-bot" },
                  [
                    _c(
                      "el-form",
                      {
                        staticClass: "demo-form-inline",
                        attrs: {
                          "label-position": "right",
                          "label-width": "110px"
                        }
                      },
                      [
                        _c(
                          "el-row",
                          _vm._l(_vm.columnsBase, function(item) {
                            return _c(
                              "el-col",
                              { key: item.label, attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: item.label + "：" } },
                                  [
                                    _c("div", [
                                      _vm._v(_vm._s(_vm.getValue(item)))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "detail-card" }, [
                _c("div", { staticClass: "card-tit" }, [_vm._v("工艺路线图")]),
                _c(
                  "div",
                  { staticClass: "card-bot process-card" },
                  [
                    _c("process-flow", {
                      ref: "processFlow",
                      attrs: {
                        "base-form": { form: _vm.baseFormData },
                        "read-only": true,
                        "process-flow-detail": _vm.processFlowVersion
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]),
          ["edit", "view"].includes(_vm.$route.query.type)
            ? _c(
                "el-tab-pane",
                { attrs: { label: "生产任务", name: "2" } },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isEdit,
                              expression: "!isEdit"
                            }
                          ],
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.batchEdit(1)
                            }
                          }
                        },
                        [_vm._v("批量编辑")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isEdit,
                              expression: "isEdit"
                            }
                          ],
                          staticStyle: { "margin-left": "0px" },
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.batchEdit(0)
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isEdit,
                              expression: "isEdit"
                            }
                          ],
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.batchSave()
                            }
                          }
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.allExport()
                            }
                          }
                        },
                        [_vm._v("全部导出")]
                      )
                    ],
                    1
                  ),
                  _c("MTable", {
                    ref: "taskTable",
                    attrs: {
                      columns: _vm.productionTaskColumn,
                      height: 500,
                      "set-data-method": _vm.getTaskData
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "productionStatus",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.productionStatus === 2
                                      ? "已完成"
                                      : row.productionStatus === 1
                                      ? "进行中"
                                      : "待开始"
                                  ) +
                                  " "
                              )
                            ])
                          }
                        },
                        {
                          key: "materialsCode",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("NewPageOpen", {
                              attrs: {
                                path: {
                                  name: "materialsDetail",
                                  query: { id: row.materialsId }
                                },
                                text: row.materialsCode
                              }
                            })
                          }
                        },
                        {
                          key: "procedureCode",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("NewPageOpen", {
                              attrs: {
                                path: {
                                  name: "procedureDetail",
                                  query: { id: row.procedureId }
                                },
                                text: row.procedureCode
                              }
                            })
                          }
                        },
                        {
                          key: "planOutputQuantity",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("div", [
                                _vm._v(_vm._s(row.planOutputQuantity))
                              ])
                            ]
                          }
                        },
                        {
                          key: "planTime",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              !_vm.isEdit
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        row.planTime[0] || row.planTime[1]
                                          ? row.planTime.join(" 至 ")
                                          : "-"
                                      )
                                    )
                                  ])
                                : _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      clearable: false,
                                      "range-separator": "至",
                                      "start-placeholder": "计划开工时间",
                                      "end-placeholder": "完工时间",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      format: "yyyy-MM-dd HH:mm:ss"
                                    },
                                    model: {
                                      value: row.planTime,
                                      callback: function($$v) {
                                        _vm.$set(row, "planTime", $$v)
                                      },
                                      expression: "row.planTime"
                                    }
                                  })
                            ]
                          }
                        },
                        {
                          key: "remark",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              !_vm.isEdit
                                ? _c("div", [_vm._v(_vm._s(row.remark))])
                                : _c("el-input", {
                                    model: {
                                      value: row.remark,
                                      callback: function($$v) {
                                        _vm.$set(row, "remark", $$v)
                                      },
                                      expression: "row.remark"
                                    }
                                  })
                            ]
                          }
                        },
                        {
                          key: "action",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onReportLog(row.id)
                                    }
                                  }
                                },
                                [_vm._v("报工记录")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      462008317
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          ["edit", "view"].includes(_vm.$route.query.type)
            ? _c(
                "el-tab-pane",
                { attrs: { label: "报工记录", name: "3" } },
                [
                  _c("MTable", {
                    ref: "mTable",
                    attrs: {
                      columns: _vm.logColumns,
                      height: 300,
                      "set-data-method": _vm.getReportLog
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "userType",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _vm._v(
                                _vm._s(row.userType === 0 ? "正式" : "外包") +
                                  " "
                              )
                            ])
                          }
                        },
                        {
                          key: "planTime",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _vm._v(
                                _vm._s(row.planStartTime) +
                                  " 至 " +
                                  _vm._s(row.planEndTime) +
                                  " "
                              )
                            ])
                          }
                        }
                      ],
                      null,
                      false,
                      3455764442
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          ["edit", "view"].includes(_vm.$route.query.type)
            ? _c(
                "el-tab-pane",
                { attrs: { label: "质检项目", name: "4" } },
                [
                  _c("FunctionHeader", {
                    ref: "functionHeader",
                    attrs: {
                      "search-title": "请输入定位码/检验项目",
                      tags: _vm.functionHeader.tags
                    },
                    on: {
                      search: function($event) {
                        return _vm.searchInspectEntity(
                          _vm.functionHeader.searchVal
                        )
                      }
                    },
                    model: {
                      value: _vm.functionHeader.searchVal,
                      callback: function($$v) {
                        _vm.$set(_vm.functionHeader, "searchVal", $$v)
                      },
                      expression: "functionHeader.searchVal"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "m-table" },
                    [
                      _c(
                        "el-table",
                        { attrs: { data: _vm.BOMList, "show-header": true } },
                        [
                          _c("el-table-column", {
                            attrs: { type: "expand" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "el-table",
                                        {
                                          staticClass: "ellipsis-table",
                                          attrs: {
                                            data: props.row.inspectEntryList
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "index",
                                              label: "序号",
                                              width: "80"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.$index + 1
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "functionCode",
                                              label: "功能码"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "inspectionEntryName",
                                              label: "检验项目"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "inspectionStandard",
                                              label: "检验标准",
                                              width: "200"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "inspectionMethod",
                                              label: "检测方法"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "parameterType",
                                              label: "检测值类型"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "parameterTypeName"
                                                            )(
                                                              scope.row
                                                                .parameterType
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "actualValue1",
                                              label: "自检实测值"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "actualValue2",
                                              label: "专检实测值"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "dutyUser",
                                              label: "生产人"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "dutyTime",
                                              label: "生产时间"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "checkUser",
                                              label: "检验人"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "checkTime",
                                              label: "检验时间"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              717942848
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "serialsCode", label: "工序定位码" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "procedureName", label: "工序名称" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          ["edit", "view"].includes(_vm.$route.query.type) && _vm.gdqtShow
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "工单齐套（" + _vm.completeRate + "%）",
                    name: "5"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                        "margin-bottom": "10px",
                        "font-size": "14px"
                      }
                    },
                    [_vm._v(" 最近查询时间：" + _vm._s(_vm.queryAt) + " ")]
                  ),
                  _c("MTable", {
                    ref: "mTable",
                    attrs: {
                      columns: _vm.completeRateColumns,
                      height: 300,
                      "set-data-method": _vm.getCompleteRateList
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        }
                      ],
                      null,
                      false,
                      1384902398
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v(_vm._s(this.type !== "view" ? "取 消" : "返 回"))
            ]),
            this.type !== "view"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.buttonLoading },
                    on: { click: _vm.save }
                  },
                  [_vm._v("确 定")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c(
        "MDialog",
        {
          attrs: { "confirm-btn": false, title: "报工记录" },
          model: {
            value: _vm.logVisible,
            callback: function($$v) {
              _vm.logVisible = $$v
            },
            expression: "logVisible"
          }
        },
        [
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.logItemColumns,
              height: 500,
              "set-data-method": _vm.getReportItemLog
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "userType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(row.userType === 0 ? "正式" : "外包") + " ")
                  ])
                }
              },
              {
                key: "planTime",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      _vm._s(row.planStartTime) +
                        " 至 " +
                        _vm._s(row.planEndTime) +
                        " "
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }