<template>
    <div class="fault-wrap common-tab">
      <div class="bottom">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="输入单号搜索"
        :tags="functionHeader.tags"  
        style="padding: 10px 0 10px 0"
        @search="$refs.mTable.setTableData(true)"
      >
      </FunctionHeader>
      <MTable ref="mTable" :columns="columnsAll" :height="height" :set-data-method="getTableData">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="inspectionType" slot-scope="{ row }">{{ inspectionTypeNames[row.businessType - 1] }}</div>
        <div slot="status" slot-scope="{ row }">
          {{ allStatus[row.status - 1] }}
        </div>
        <div slot="action" slot-scope="{ row }" style="z-index: 100">
          <el-button v-if="permission('Detail')" type="text" @click="viewDetail(row.id)">详情</el-button>
        </div>
      </MTable>
      </div>
  
    </div>
  </template>
  <script>
  import FunctionHeader from '@/components/FunctionHeader'
  import api from '@/api/qualityManagement/defective-product-judge'
  import { columnsAll } from './columns'
  export default {
    name: 'DefectiveProductJudgeList',
    components: { FunctionHeader },
    data() {
      return {
        searchFormData: [],
        searchFormDatas: {},
        keyword: '',
        selectKeyword: '1',
        functionHeader: {
          searchVal: ''
        },
        columnsAll,
        allStatus: ['待处理','已处理','已作废'],
        inspectionTypeNames: ['自制工序自检', '自制工序专检', '委外工序检验'],
        searchDatas: [],
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 192 - 15
      }
    },
    watch: {
    },
    mounted() {
    },
    methods: {
      viewDetail(id) {
        this.$router.push({ name: 'DefectiveProductJudgeDetail', query: { id }})
      },
      // 列表
      async getTableData(condition, callback) {
        const { page, order } = condition
        const data = Object.assign({}, ...this.searchDatas)
        const res = await api.getDefectProductJudgmentListPage({
          page: page.currentPage,
          size: page.pageSize,
          number: this.functionHeader.searchVal,
          ...data
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      }
    }
  }
  </script>
    <style scoped lang="scss">
     .fault-wrap{
        // padding: 10px;
        .item-wrapper {
          > div {
            display: inline-flex;
            padding: 10px 5px 0 5px;
            align-items: center;
            font-size: 14px;
  
            .label {
              min-width: 115px;
            }
  
          }
        }
        .bottom {
          width: 100%;
          height: 100%;
          padding: 10px;
        }
        .all-select {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .checkAll {
            margin-right: 20px;
          }
          .all-li {
            width: 80px;
            height: 26px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 1px solid #DDE3EB;
            margin-right: 15px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A4B3C6;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .active {
            color: #607FFF;
            border-color: #607FFF;
            background: #EFF2FF;
          }
        }
        .li-btn{
          // margin-right:10px;
          color:#607FFF;
          font-weight: 500;
        }
        .color-btns{
          color:#c4c4c4;
        }
        .searchs{
          padding-left: 0;
          padding-top: 0;
        }
        .input-select{
          width: 100px;
          display: block;
          }
        .input-with-select{
          width: 100%;
        }
        .all-operator{
          height: 32px;
          margin-left: 10px;
          ::v-deep{
            .el-button-group .el-button{
              height: 32px;
            }
          }
        }
     }
    </style>  