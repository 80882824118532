<template>
  <div class="scrap-wrap">
    <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList"
        :form-data.sync="searchFormData"
        :default-form="defaultForm"
        @search="searchTable" />
    </div>
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入项目名称/编号/客户"
        :tags="functionHeader.tags"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        @search="$refs.mTable.setTableData(true)"
      >
      </FunctionHeader>
      <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <NewPageOpen slot="projectNumber" slot-scope="{ row }" :path="getDetailPath(row, 'view')" :text="row.projectNumber" />
        <div slot="deliveryType" slot-scope="{ row }" >
          <span>{{ projectDeliveryType[row.deliveryType].name }}</span>
        </div>
        <div slot="deliveryDate" slot-scope="{ row }" >
          <span>{{ row.deliveryStartDate }}~{{row.deliveryEndDate}}</span>
        </div>
         <div slot="businessUnit" slot-scope="{ row }" >
          <span>{{ row.businessUnit!=null ?divisionOptions[row.businessUnit - 1].name : '' }}</span>
        </div>
        <div slot="principalName" slot-scope="{row }">
        <el-tooltip effect="dark" :content="row.principalName"  placement="top-end">
          <span class="mr-10">
            {{ row.principalName }}
         </span>
        </el-tooltip>
      </div>
        <div slot="executiveUnit" slot-scope="{ row }" >
          <span>{{row.executiveUnit!=null ?executionDepartment[row.executiveUnit].name : '' }}</span>
        </div>
        <div slot="projectApprovalNumber" slot-scope="{ row }" >
          <span>{{row.projectApprovalNumber }}</span>
        </div>
        <div slot="projectStatus" slot-scope="{ row }" >
          <span>{{ projectStatus[row.projectStatus].name }}</span>
        </div>
        <div slot="auditStatus" slot-scope="{ row }" >
          <span>{{ checkStatus[row.auditStatus].name }}</span>
        </div>
        <div slot="action" slot-scope="{ row }">
          <el-button
                v-if="permission('Print')"
                type="text"
                @click="printLabel(row)"
                >机台打印</el-button
              >
              <el-divider v-if="permission('Print')" direction="vertical" />

          <el-button v-if="permission('Over')" type="text" @click="onOver(row.id)"
                  :disabled="row.projectStatus === 4 ? true:false"
                  >结案</el-button
                >
                <el-divider v-if="permission('Over')" direction="vertical" />
                <el-button v-if="permission('Restart')" type="text" @click="restart(row.id)"
                  :disabled="row.projectStatus === 4 ? false:true"
                  >重启</el-button
                >
                <el-divider v-if="permission('Restart')" direction="vertical" />
              <el-button
                v-if="permission('Modify')"
                type="text"
                @click="modify(row)"
                :disabled="row.projectStatus === 4 ? true:false"
                >修改</el-button
              >
              <el-divider v-if="permission('Modify')" direction="vertical" />
              <el-button
                v-if="permission('Del')"
                type="text"
                @click="del(row.id)"
                :disabled="row.projectStatus === 0 ? false:true"
                >删除</el-button
              >
              <!-- <el-divider v-if="permission('Del')" direction="vertical" /> -->

            </div>
      </MTable>
    </div>
    <MDialog v-model="visible" :title="titles[currentType]" @onOk="submitForm">
    <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" >
      <div slot="finishTime">
        <div style="margin-top:40px;">
          <span>完善计划节点信息</span>
          <i class="el-icon-arrow-down" v-show="showNodes" @click="showNodes = false" style="cursor:pointer;font-size: 20px;"></i>
          <i class="el-icon-arrow-up" v-show="!showNodes" @click="showNodes = true" style="cursor:pointer;font-size: 20px;"></i>
        </div>
      </div>
      <div slot="nodeTable">
        <div v-show="showNodes">
        <MTable
          :columns="nodeColumns"
          :height="500"
          :data="planNodeList"
          :show-page="false"
          :columns-setting="false"
        >
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <div slot="startTime" slot-scope="{row }">
          <el-date-picker
                v-model="row.startTime"
                type="datetime"
                placeholder="开始时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="'00:00:00'"
                :disabled="(row.startTimeAttribute === 2) || (currentType === 'modify' && row.projectPlanId === projectPlanId)"
                clearable
                style="width:100%"
              />
          </div>
          <div slot="finishTime" slot-scope="{row}">
          <el-date-picker
                v-model="row.finishTime"
                type="datetime"
                placeholder="完成时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="'00:00:00'"
                :disabled="(row.finishTimeAttribute === 2) || (currentType === 'modify' && row.projectPlanId === projectPlanId)"
                clearable
                style="width:100%"
              />
          </div>
          <div slot="principalName" slot-scope="{row}">
           <el-select
            v-model="row.principalId"
            size="small"
            filterable
            clearable
            multiple
            collapse-tags
            style="width: 100%"
            :disabled="currentType === 'modify' && row.projectPlanId === projectPlanId"
            placeholder='负责人'
          >
            <el-option
              v-for="item in principalLst"
              :key="item.id"
              :label="item.userFullName"
              :value="item.id"
            />
          </el-select>
          </div>

        </MTable>
      </div>
      </div>
      <div slot="deliveryDate">
                    <el-date-picker
                      v-show="!showTwoTime"
                      v-model="deliveryOneDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      style="width: 100%"
                      placeholder="请选择交付日期"
                      @change="chooseStartTime"
                      clearable
                    />
                    <el-date-picker
                    v-show="showTwoTime"
                          v-model="deliveryTwoDate"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd"
                          format="yyyy-MM-dd"
                          clearable
                          style="width: 100%"
                        @change="chooseStartTime"
                        />
                  </div>
    </MFormBuilder>
  </MDialog>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns,getFunctionList,nodeColumns } from './columns'
import api from '@/api/projectManagement/project'
import conventionMixin from '@/mixin/conventional-page'
import batchExportFront from '@/mixin/batch-export-front'
import FunctionHeader from '@/components/FunctionHeader/index'
import { procedureClass } from '@/config/options.config'
import CURDSelect from '@/components/CURDSelect/CURDSelect'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import { projectDeliveryType,checkStatus,projectStatus } from '@/config/options.config'
import { divisionOptions, executionDepartment } from '@/views/plans/prod-plan-lists/data'
import {formList, searchFormList, getPlanFunctionList } from './form-list'
import processApi from '@/api/information/production/process'
import sapi from '@/api/projectManagement/planScheme'


export default {
  name: 'ProjectManagementList',
  components: { NewPageOpen, CURDSelect, SearchForm, FunctionHeader },
  mixins: [conventionMixin, batchExportFront],
  filters: {
    deliveryType(val) {
          if (val || val=== 0) return projectDeliveryType.find(item => item.id === val).name
      }
  },
  data() {
    return {
      api,
      isProcess: false,
      projectNumber:'',
      procedureClass,
      projectDeliveryType,
      divisionOptions,
      executionDepartment,
      checkStatus,
      projectStatus,
      formList,
      params: {
        radio: 'Project'
      },
      columns,
      searchFormData: {},
      defaultSearchFormData: {
        procedureId: ''
      },
      titles: {
      add: '新增项目',
      modify: '修改项目'
    },
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
        {
          hidden: !this.permission('Add'),
          tagName: 'el-button',
          props: { type: 'primary', size: 'small' },
          style: { width: 'auto', background: '#607FFF' },
          innerText: '新增',
          on: {
            click: this.add
          }
        }
        ],
        exportName: '',
        exportParams: {}
      },
      formData: {},
      currentType: 'add',
      visible: false,
      stationVisible: false,
      stationList: [],
      searchKeyword: true,
      defaultForm: { status: [0, 2] },
      isPageLoaded:false,
      materialParams: { status: 0, page: 1, size: 10, search: '',id:'' },
      customerParams:{ page: 1, size: 20, search: '',customerId:''},
      planNodeList:[],
      nodeColumns,
      showNodes:false,
      principalLst:[],
      projectPlanId:'',
      showTwoTime:true,
      deliveryOneDate:'',
      deliveryTwoDate:[],
      searchFormList
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'refresh') {
      this.$store.dispatch('tagsView/addCachedView', from)
    }
    next()
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 10 + 35
    },
  },
  watch: {
  'materialParams.search'(val) {
    // this.formList[10].children[0].options.length = 0
    this.materialParams.page = 1
    // this.$nextTick(() => {
    //   this.getFinishedMaterials(this.materialParams)
    // })
  },
  'customerParams.search'(val) {
    this.formList[3].children[0].options.length = 0
    this.customerParams.page = 1
    this.$nextTick(() => {
      this.getCustomerList(this.customerParams)
    })
  },
  visible(val) {
      // this.formList[10].children[0].options.length = 0
      !val ? this.materialParams = { status: 0, page: 1, size: 20, search: '',id:'' }:
       this.materialParams = { status: 1, page: 1, size: 20, search: '',id: this.materialParams.id}
      // this.getFinishedMaterials(this.materialParams)
    }
  },
  created() {
  // this.getFinishedMaterials(this.materialParams)
  this.getCustomerList(this.customerParams)
  this.getUserList()
  this.getBusinessUnitList()
  this.formList[3].directives[0].value = this.customerLoadMore
  this.formList[3].props.remoteMethod = (val) => { this.customerParams.search = val }
  // this.formList[10].directives[0].value = this.finishedMaterialLoadMore
  // this.formList[10].props.remoteMethod = (val) => { this.materialParams.search = val }
  },
  activated(){
  if(!this.isPageLoaded){
    this.$refs.mTable.setTableData()
  }
  this.isPageLoaded = false
  },
  beforeMount() {
  getFunctionList({
    searchChange: this.searchChange
  }),
  getPlanFunctionList({
    onRecordTypeChange: this.onRecordTypeChange
  })
},
  mounted() {
    this.isPageLoaded = true
  },
  methods: {
    async chooseStartTime(val) {
      await this.$refs.formBuild.setForm({
        deliveryDate:val
      })
      this.$refs.formBuild.formRefs().validateField('deliveryDate')
    },
    searchChange(val){
     this.showTwoTime = val ? true : false
     this.$refs.formBuild.setForm({
      deliveryDate: ''
      });
     this.deliveryOneDate = ''
     this.deliveryTwoDate = []
  },
    async getTableData(condition, callback) {
      const { page, search, order } = condition

      // const data = Object.assign({}, ...this.searchFormatData)
      const searchCondition = {
        search: this.functionHeader.searchVal,
        status: this.searchFormData.status ? this.searchFormData.status.join(",") : '',
        // ...data
      }
      this.functionHeader.exportParams = searchCondition
      const res = await this.api[`get${this.params.radio}ListPage`]({
        page: page.currentPage,
        size: page.pageSize,
        ...searchCondition
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    async onRecordTypeChange(val){
      if(this.projectPlanId && this.projectPlanId != val && val){
         this.$message.info('更改发案会覆盖之前的项目节点数据！')
      }
      if(val){
        await this.getProjectPlanDetail(val)
      }else{
        this.materialParams.search = ''
        this.materialParams.id = ''
        // this.formList[10].children[0].options = []
        // this.getFinishedMaterials(this.materialParams)
        this.planNodeList = []
      }

    },
    async getProjectPlanDetail(val){
      if(val){
      const res = await sapi.getPlanSchemeDetail({id:val})
        if(res){
          this.planNodeList = res.nodeViewList.map(item=>{
            return {
              ...item,
              projectPlanId:val
            }
          })
        }
      }else{
        this.planNodeList = []
      }
    },
    printLabel(row) {
      this.jumpDetailNewTab(row)
  },
  jumpDetailNewTab(row) {
    this.moreTabSetSessionStorage()
    const path = { name: 'ProjectBasePrint', query: { id: row.id }}
    const routeUrl = this.$router.resolve(path)
    window.open(routeUrl.href, '_blank')
  },
  moreTabSetSessionStorage() {
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', Date.now())
    }
    window.addEventListener('storage', (event) => {
      if (event.key === 'getSessionStorage') {
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
        localStorage.removeItem('sessionStorage')
      } else {
        if (event.key === 'sessionStorage' && !sessionStorage.length) {
          const data = JSON.parse(event.newValue).value
          for (const key in data) {
            sessionStorage.setItem(key, data[key])
          }
        }
      }
    })
  },
   // 结案
   onOver(id) {
       this.$confirm('您确定要结案吗?', '提示', {
           confirmButtonText: '确定',
           cancelButtonText: '取消',
           type: 'warning'
         }).then(async() => {
           const res = await api.closeOut(id)
           if (res) {
             this.$message.success('操作成功')
             this.$refs.mTable.setTableData()
           }
         })
    },
    restart(id) {
       this.$confirm('您确定要重启吗?', '提示', {
           confirmButtonText: '确定',
           cancelButtonText: '取消',
           type: 'warning'
         }).then(async() => {
           const res = await api.restart(id)
           if (res) {
             this.$message.success('操作成功')
             this.$refs.mTable.setTableData()
           }
         })
      },
  del(id) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delProject({ id:id })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
  // 用户下拉触底分页加载
  finishedMaterialLoadMore() {
      // if (this.formList[10].children[0].options.length === this.materialsTotal) return
      // if (this.formList[10].children[0].options.length > this.materialsTotal) return [...this.formList[10].children[0].options = new Set(this.formList[10].children[0].options)]
      // this.materialParams.page++
      // this.getFinishedMaterials(this.materialParams)
  },
  // 客户下拉触底分页加载
  customerLoadMore() {
    if (this.formList[3].children[0].options.length === this.customerTotal) return
      if (this.formList[3].children[0].options.length > this.customerTotal) return [...this.formList[3].children[0].options = new Set(this.formList[3].children[0].options)]
    this.customerParams.page++
    this.getCustomerList(this.customerParams)
  },
  // 获取负责人
  async getUserList() {
    const res = await api.getResponsible()
    if (res) {
      this.formList[6].children[0].options = res
      this.principalLst = res
    }
  },
  // 获取业务部门
   async getBusinessUnitList() {
      const res = await api.getBusinessUnitList()
      if (res) {
        this.formList[7].children[0].options = res
      }
    },
  // 获取计划方案
  async getFinishedMaterials(params) {
    const res = await sapi.getPlanSchemeListPage(params)
    if (res) {
      this.materialsTotal = res.total
      // this.formList[10].children[0].options.push(...res.records || [])
    }
  },
  // 获取客户
  async getCustomerList(params) {
    const res = await processApi.getCustomerListPage(params)
    if (res) {
      this.customerTotal = res.total
      this.formList[3].children[0].options.push(...res.records || [])
    }
  },
    getDetailPath(row, command) {
      return { name: 'ProjectManagementDetail', query: { id: row.id}}
    },
    async add() {
      localStorage.removeItem('taskNodeList')
      // const res = await api.getProjectCode()
      //   if(res){
          this.formList[0].props.disabled = false
          //  this.formData = { deliveryDate:''}
        // }
        // this.formList[3].children[0].options = []
        // this.formList[10].children[0].options = []
        // this.materialParams.id = ''
        // this.getCustomerList(this.customerParams)
        // this.currentType = 'add'
        // this.showNodes = false
        // this.planNodeList = []
        // this.visible = true
        this.$router.push({ name: 'AddProject' })
  },
  async modify(row){
    this.formData = this._.cloneDeep(row)
    this.formList[0].props.disabled = true
    this.showTwoTime = this.formData.deliveryType ? true : false
    this.formData.principalId = this.formData.principalId ? this.formData.principalId.split(',').map(item => +item) : this.formData.principalId
    this.currentType = 'modify'
    this.formData.deliveryDate = this.formData.deliveryType ? [this.formData.deliveryStartDate,this.formData.deliveryEndDate]:this.formData.deliveryStartDate
    this.deliveryOneDate = this.formData.deliveryStartDate
    this.deliveryTwoDate = this.formData.deliveryType ? [this.formData.deliveryStartDate,this.formData.deliveryEndDate]:[]
    this.formList[3].children[0].options = []
    // this.formList[10].children[0].options = []
    this.materialParams = { status: 1, page: 1, size: 10, search: '',id: row.projectPlanId}
    this.projectPlanId = row.projectPlanId
    this.showNodes = false
    await this.getProjectPlanDetail(row.projectPlanId)
    // this.getFinishedMaterials(this.materialParams)
    this.getCustomerList(this.customerParams)
    this.visible  = true
  },
  createSetData(raw) {
    return {
      projectNumber:raw.projectNumber,
      projectName:raw.projectName,
      customerOrderNumber:raw.customerOrderNumber,
      customerId:raw.customerId,
      deliveryType:raw.deliveryType,
      deliveryStartDate:raw.deliveryType ? raw.deliveryDate[0] :raw.deliveryDate,
      deliveryEndDate:raw.deliveryType ? raw.deliveryDate[1] :raw.deliveryDate,
      principalId:raw.principalId ? raw.principalId.join(','):'',
      businessUnit:raw.businessUnit,
      executiveUnit:raw.executiveUnit,
      projectApprovalNumber:raw.projectApprovalNumber,
      projectPlanId:raw.projectPlanId,
      // planNodeList:this.planNodeList.map(item=>{
      //    return {
      //     ...item,
      //     principalId:item.principalId ? item.principalId.join(','):'',
      //     projectPlanNodeId:item.id
      //    }
      // })
    }
  },
  createResetData(raw) {
    const obj = {
      id: raw.id
    }
    return Object.assign(this.createSetData(raw), obj)
  },
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;
  .bottom {
    padding: 10px;
  }
  .m-radio-group {
    margin-left: 10px;
  }
}
.role-tags {
  margin: 5px;
  border-color: #dde3eb;
  color: #9597ae;
}
::v-deep {
  .e-divider {
    margin: 0px 0 0 0;
  }
  .el-form-item__content {
    height:32px;
  }
}
</style>
