<template>
  <div v-if="hadLogin" class="memberRouteSetting_container" :style="{height: chromeHeight + 'px'}">
    <!--    查询条件-->
    <el-row style="width: 100%;height:50px;align-items: center;" type="flex" justify="space-around">
      <el-col :span="1">
        <el-button type="primary" @click="addShow">新增</el-button>
      </el-col>
      <el-col :span="4">
        <el-input @change="changeSrc" placeholder="请输入租户code/企业名称" v-model="srcCondition.codeOrName"
                  clearable></el-input>
      </el-col>
      <el-col :span="5">
        <el-date-picker
          @change="changeSrc"
          v-model="srcCondition.startTimeRange"
          type="daterange"
          range-separator="-"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="最早开始时间"
          end-placeholder="最晚开始时间">
        </el-date-picker>
      </el-col>
      <el-col :span="5">
        <el-date-picker
          @change="changeSrc"
          v-model="srcCondition.endTimeRange"
          type="daterange"
          range-separator="-"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="最早结束时间"
          end-placeholder="最晚结束时间">
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <el-select @change="changeSrc" v-model="srcCondition.isProd" placeholder="是否正式">
          <el-option
            v-for="item in isProdList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select @change="changeSrc" v-model="srcCondition.status" placeholder="状态">
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <!--    内容显示区域-->
    <MTable
      ref="mTable"
      style="margin-top: 15px;"
      :columns="columnsAll"
      highlight-current-row
      :set-data-method="getTableData"
      :height="height"
    >
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

      <div slot="isAuthorized" slot-scope="{ row }">
        <div>{{ row.isAuthorized ? '正式' : '非正式' }}</div>
      </div>

      <div slot="isEnabled" slot-scope="{ row }">
        <div>{{ row.isEnabled ? '已启用' : '未启用' }}</div>
      </div>

      <div slot="tenantType" slot-scope="{ row }">
        <div>{{ row.tenantType == 'mom' ? '装备云' : '数字产线' }}</div>
      </div>

      <div slot="action" slot-scope="{ row }">
        <el-button v-if="row.isEnabled" type="text" @click="lockOrOpen(false,row.id)">禁用</el-button>
        <el-button v-else type="text" @click="lockOrOpen(true,row.id)">启用</el-button>
        <el-button type="text" @click="modifyShow(row)">编辑</el-button>
        <el-button type="text" @click="deleteRow(row)">删除</el-button>
      </div>

    </MTable>

    <!--    新增-->
    <el-dialog
      v-if="centerDialogVisible"
      :title="nowView == 'add' ? '新增租户' : '修改租户信息'"
      :visible.sync="centerDialogVisible"
      width="50%"
      center>

      <el-form style="width: 60%;margin: 10px auto" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px"
               class="demo-ruleForm">
        <el-form-item label="租户ID" prop="memberId" v-if="nowView == 'modify'">
          <el-input v-model="ruleForm.memberId" disabled></el-input>
        </el-form-item>
        <el-form-item label="租户编码" prop="memberCode">
          <el-input v-model="ruleForm.memberCode" :disabled="nowView == 'modify'"></el-input>
        </el-form-item>
        <el-form-item label="租户名称" prop="memberName">
          <el-input v-model="ruleForm.memberName"></el-input>
        </el-form-item>
        <el-form-item label="有效期" prop="expDate">
          <el-date-picker
            style="width: 100%"
            v-model="ruleForm.expDate"
            type="daterange"
            range-separator="-"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="有效期起"
            end-placeholder="有效期止">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否正式" prop="isAuthorized">
          <el-radio-group v-model="ruleForm.isAuthorized">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="租户模板" prop="templateId" v-if="nowView == 'add'">
          <el-select style="width: 100%" v-model="ruleForm.templateId" placeholder="请选择租户初始化数据模板">
            <el-option
              v-for="item in templateMemberList"
              :key="item.id"
              :label="item.memberName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否模板" prop="templateTenant" v-if="nowView == 'add'">
          <el-radio-group v-model="ruleForm.templateTenant">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="环境参数" prop="nothing" v-if="nowView == 'add' && momOrLine">
          {{ momOrLine }}
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button style="margin-left: -20px" @click="centerDialogVisible = false">取 消</el-button>
        <el-button :loading="loading" style="margin-left: 40px" type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
  <!--  处理登录-->
  <div v-else>
    <div class="login-right" style="width: 100vw;height: 100vh;background: rgba(3, 13, 32, 0.6);">
      <div class="center" style="width: 30%;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -80%);">
        <div class="blank-placeholder"/>
        <div class="form-wrapper">
          <div class="cac-logo" style="display: flex;justify-content: center;align-items: center;margin-bottom: 20px">
            <img src="https://oss.mubyte.cn/static/logo-new%402x.png" style="display: block;width: 200px">
          </div>
          <div class="login-tab">
            <div class="form" style="border-top-left-radius: 8px;">
              <el-form ref="loginForm" :model="loginForm" class="login-form"
                       label-position="left">
                <div>
                  <el-form-item>
                    <el-input  v-model="loginForm.userName" class="inline-input"
                              placeholder="请输入用户名" style="width: 100%"
                              tabindex="1" autocomplete="off"/>
                  </el-form-item>
                  <el-form-item style="padding-bottom: 0">
                    <el-input autocomplete="off"  v-model="loginForm.password" show-password
                              placeholder="请输入密码"  tabindex="2" @keyup.enter.native="handleLogin"/>
                  </el-form-item>
                </div>
                <el-button :loading="loading" type="primary" style="width:100%;color: #fff"
                           @click.native.prevent="handleLogin">立即登录
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/tenant'
import axios from 'axios'
import { setRouteUserNameMd5, setRoutePasswordMd5,tenantLoginUserNameMd5,tenantLoginPasswordMd5 } from '@/settings'
import { Encrypt } from '@/utils/sercet'

export default {
  name: 'MemberManagement',
  data() {
    return {
      templateMemberList: [],
      ruleForm: {
        memberId: '',
        memberCode: '',
        memberName: '',
        expDate: '',
        isAuthorized: 1,
        templateId: '',
        templateTenant: 0
      },
      rules: {
        memberCode: [
          { required: true, message: '请输入租户编码', trigger: 'blur' }
        ],
        memberName: [
          { required: true, message: '请输入租户名称', trigger: 'blur' }
        ]
      },
      centerDialogVisible: false,
      columnsAll: [
        {
          prop: 'index',
          label: '序号',
          slotName: 'index',
          hiddenSearch: true,
          sortable: false,
          width: 50
        },
        {
          prop: 'tenantId',
          label: '租户ID',
          hiddenSearch: true,
          sortable: false,
          width: 80
        },
        {
          prop: 'memberCode',
          label: '租户code',
          hiddenSearch: true,
          sortable: false,
          width: 130
        },
        {
          prop: 'memberName',
          label: '企业名称',
          hiddenSearch: true,
          sortable: false,
          width: 140
        },
        {
          prop: 'startTime',
          label: '开始时间',
          hiddenSearch: true,
          sortable: false,
          width: 160
        },
        {
          prop: 'endTime',
          label: '结束时间',
          hiddenSearch: true,
          sortable: false,
          width: 160
        },
        {
          prop: 'isAuthorized',
          slotName: 'isAuthorized',
          label: '是否正式',
          hiddenSearch: true,
          sortable: false,
          width: 100
        },
        {
          prop: 'isEnabled',
          slotName: 'isEnabled',
          label: '状态',
          hiddenSearch: true,
          sortable: false,
          width: 90
        },
        {
          prop: 'tenantType',
          slotName: 'tenantType',
          label: '租户类型',
          hiddenSearch: true,
          sortable: false,
          width: 120
        },
        {
          prop: 'createBy',
          label: '创建人',
          hiddenSearch: true,
          sortable: false,
          width: 120
        },
        {
          prop: 'createAt',
          label: '创建时间',
          hiddenSearch: true,
          sortable: false,
          width: 160
        },
        {
          prop: 'updateBy',
          label: '更新人',
          hiddenSearch: true,
          sortable: false,
          width: 120
        },
        {
          prop: 'updateAt',
          label: '更新时间',
          hiddenSearch: true,
          sortable: false,
          width: 160
        },
        {
          slotName: 'action',
          label: '操作',
          fixed: 'right',
          hiddenSearch: true,
          sortable: false,
          width: 160
        }
      ],
      loginForm: {
        userName: '',
        password: ''
      },
      srcCondition: {
        codeOrName: '',
        startTimeRange: '',
        endTimeRange: '',
        isProd: '',
        status: ''
      },
      isProdList: [
        {
          label: '非正式',
          value: 0
        },
        {
          label: '正式',
          value: 1
        }
      ],
      statusList: [
        {
          label: '未启用',
          value: 0
        },
        {
          label: '已启用',
          value: 1
        }
      ],
      hadLogin: false,
      loading: false,
      nowView: 'view',
      nowRow: {}
    }
  },
  computed: {
    momOrLine(){
      const msg = this.templateMemberList.filter(item => item.id === this.ruleForm.templateId)
      if(msg.length){
        if(msg[0].tenantType == 'mom'){
          return '装备云'
        }else if(msg[0].tenantType == 'line'){
          return '数字产线'
        }else {
          return '未知'
        }
      }else {
        return ''
      }
    },
    height() {
      return this.$store.state.app.contentHeight - 192 - 35
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    chromeHeight() {
      return document.documentElement.clientHeight
    }
  },
  async created() {
    const res = await api.getTemplateTenantList()
    if (res) {
      this.templateMemberList = res
    }
  },
  beforeMount() {

  },
  methods: {
    ttt() {
      this.$refs.ruleForm.resetFields()
    },
    addShow() {
      this.nowView = 'add'
      this.centerDialogVisible = true
      this.ruleForm = {
        memberId: '',
        memberCode: '',
        memberName: '',
        expDate: '',
        isAuthorized: 1,
        templateId: '',
        templateTenant: 0
      }
    },
    modifyShow(row) {
      this.nowRow = row
      this.nowView = 'modify'
      this.ruleForm = {
        memberId: row.tenantId,
        memberCode: row.memberCode,
        memberName: row.memberName,
        expDate: [row.startTime.slice(0, 10), row.endTime.slice(0, 10)],
        isAuthorized: row.isAuthorized
      }
      this.centerDialogVisible = true
    },
    deleteRow(row){
      this.$confirm('此操作将永久删除该租户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const formData = new FormData()
        formData.append('id', row.id)
        const res = await api.deleteMember(formData)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      }).catch(() => {

      })
    },
    submitAdd() {
      console.log('新增/修改---数据包', this.ruleForm)
      if (this.nowView == 'add') {
        this.$refs.ruleForm.validate(async(valid) => {
          if (valid) {
            this.loading = true
            const addMsg = {
              'memberCode': this.ruleForm.memberCode || '',
              'memberName': this.ruleForm.memberName || '',
              'startTime': this.ruleForm.expDate ? this.ruleForm.expDate[0] + ' 00:00:00' : '',
              'endTime': this.ruleForm.expDate ? this.ruleForm.expDate[1] + ' 23:59:59' : '',
              'isAuthorized': this.ruleForm.isAuthorized,
              'templateId': this.ruleForm.templateId,
              'templateTenant': this.ruleForm.templateTenant
            }
            debugger
            const res = await api.addNewMember(addMsg)
            if (res) {
              this.$message.success('新增成功')
              this.centerDialogVisible = false
              this.loading = false
              this.$refs.mTable.setTableData()
            } else {
              this.loading = false
            }
          } else {
            return false
          }
        })
      } else if (this.nowView == 'modify') {
        this.$refs.ruleForm.validate(async(valid) => {
          if (valid) {
            this.loading = true
            const modifyMsg = {
              'id': this.nowRow.id,
              'tenantId': this.nowRow.tenantId,
              'memberName': this.ruleForm.memberName || '',
              'startTime': this.ruleForm.expDate ? this.ruleForm.expDate[0] + ' 00:00:00' : '',
              'endTime': this.ruleForm.expDate ? this.ruleForm.expDate[1] + ' 23:59:59' : '',
              'isAuthorized': this.ruleForm.isAuthorized
            }
            debugger
            const res = await api.modifyMember(modifyMsg)
            if (res) {
              this.$message.success('修改成功')
              this.centerDialogVisible = false
              this.loading = false
              this.$refs.mTable.setTableData()
            } else {
              this.loading = false
            }
          } else {
            return false
          }
        })

      }
    },
    async lockOrOpen(flag, id) {
      let formData = new FormData()
      formData.append('id', id)
      if (flag) {
        const res = await api.enableTenant(formData)
        if (res) {
          this.$message.success('启用成功')
          this.$refs.mTable.setTableData()
        }
      } else {
        const res = await api.disableTenant(formData)
        if (res) {
          this.$message.success('禁用成功')
          this.$refs.mTable.setTableData()
        }
      }
    },
    changeSrc() {
      this.$refs.mTable.setTableData()
    },
    async getTableData(condition, callback) {
      consoleSelf.info('查询条件', this.srcCondition)
      const { page } = condition
      const obj = {
        'tenant': this.srcCondition.codeOrName || '',
        'earliestStartTime': this.srcCondition.startTimeRange ? this.srcCondition.startTimeRange[0] : '',
        'latestStartTime': this.srcCondition.startTimeRange ? this.srcCondition.startTimeRange[1] : '',
        'earliestEndTime': this.srcCondition.endTimeRange ? this.srcCondition.endTimeRange[0] : '',
        'latestEndTime': this.srcCondition.endTimeRange ? this.srcCondition.endTimeRange[1] : '',
        'isAuthorized': this.srcCondition.isProd,
        'isEnabled': this.srcCondition.status,
        'page': page.currentPage,
        'size': page.pageSize
      }
      const res = await api.getMemberList(obj)
      if (res) {
        consoleSelf.info('查询结果', res.records)
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    handleLogin() {
      console.log(this.loginForm)
      if (!this.loginForm.userName || !this.loginForm.password) {
        this.$message.error('请输入账号和密码')
        return false
      }
      const userName = this.loginForm.userName
      const userPwd = Encrypt(this.loginForm.password)
      if (userName === 'mubai' && (userPwd === tenantLoginPasswordMd5 || userPwd === '6e2992c1e4389468d4bd1b68f3679558')) {
        this.$message.success('登录成功')
        this.hadLogin = true
      } else {
        this.$message.error('账号或密码错误')
        return false
      }
    },
    async viewCheckItem(index) {
      this.curtenIndex = index
      const users = await this.getUserList(this.userList[index].memberCode)
      this.showUsers = users
      const userRoute = await this.getTenatDetail(this.userList[index].id)
      this.selectedUserMsg = userRoute
      // consoleSelf.info('获取到当前租户的权限信息', userRoute)
      const originMsg = JSON.parse(userRoute.value) || ''
      if (originMsg) {
        const { web, app } = originMsg
        const finalWeb = web.filter(item => this.judgeWeb.includes(item))
        const finalApp = app.filter(item => this.judgeApp.includes(item))
        this.authWeb = finalWeb
        this.authApp = finalApp
        this.openPanel = false
        this.openPanelApp = false
        this.$nextTick(() => {
          this.openPanel = true
          this.openPanelApp = true
        })
      }
    },
    async getTenatDetail(id) {
      const res = await api.getTenantDetail({
        id
      })
      if (res) {
        return res.basicConfig
      } else {
        return null
      }
    },
    async getUserList(memberCode) {
      const res = await api.getTenantUserListPage({
        page: 1,
        limit: 100,
        memberCode
      })
      if (res) {
        return res.records
      } else {
        return []
      }
    },
    radioChange(type) {
      this.params.radio = type
    }
  }
}
</script>

<style scoped lang="scss">


.memberRouteSetting_container {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;

  .demo-ruleForm .el-form-item {
    margin-bottom: 22px;
  }
}
</style>

