<template>
    <div class="orderAdd">
      <div class="procedure-detail-header">
        <div class="left" @click="$router.push({ name: 'DefectiveProductJudgeList' })">
          <img src="@/assets/information/procedure/左滑@2x.png">
          <span>不良品判定详情</span>
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <el-col :span="6">
            <span class="bot-ti">单号：{{  detailData.number }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">业务类型：{{ inspectionTypeNames[detailData.businessType - 1] }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">状态：{{ allStatus[detailData.status - 1] }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">物料编码：{{ detailData.materialsCode }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">物料名称：{{ detailData.materialsName }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">物料规格：{{ detailData.materialsSpecifications }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">不良数量：{{ detailData.defectQuantity }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">处理时间：{{ detailData.handleTime }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">创建人：{{ detailData.creator }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">创建日期：{{ detailData.createTime }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">项目：{{ detailData.projectName }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">源单号：{{ detailData.sourceNumber }}</span>
          </el-col>
        </div>
      </div>
      <div class="detail-card" >
        <div class="tables">
          <MTable
            ref="mTable"
            :height="410"
            :columns="columnsDetail"
            :set-data-method="getTableData"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="judgmentType" slot-scope="{ row }">{{ row.judgmentType ? judgmentTypeList[row.judgmentType - 1] :'-' }}</div>
          </MTable>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { columnsDetail } from './columns'
  import api from '@/api/qualityManagement/defective-product-judge'
  
  export default {
    name: 'DefectiveProductJudgeDetail',
    data() {
      return {
        columnsDetail,
        userList: [],
        show: false,
        orderList: [],
        detailData: {
          source: 0
        },
        allStatus: ['待处理','已处理','已作废'],
        inspectionTypeNames: ['自制工序自检', '自制工序专检', '委外工序检验'],
        judgmentTypeList:['让步接收', '生产返修', '供应商返修'],
        id: undefined
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 289 - 40 - 54 + 29 - 62 - 22
      }
    },
    mounted(){
        this.getOrderDetail(this.$route.query.id)
    },
    methods: {
      // 列表
      async getTableData(condition, callback) {
        const { page, search, order } = condition
        const res = await api.getDefectProductJudgmentItemListPage({
          page: page.currentPage,
          size: page.pageSize,
          defectProductJudgmentId:this.$route.query.id
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
      async getOrderDetail(id) {
        const res = await api.getDefectProductJudgmentDetail({id:id})
        if (res) {
          this.detailData = res
        }
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  ::v-deep {
  .table-row-img {
    >img {
      width: 100px;
    }
  }
  }
  .bot-li {
    display: flex;
    justify-content: flex-start;
    .bot-ti {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      display: inline-block;
      margin-bottom: 15px;
    }
  }
  .el-tags {
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .icon-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 19px;
    height: 19px;
    display: block;
  }
  .all-ipt {
    margin-top: 4px;
  }
  .procedure-detail-header {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456b;
    line-height: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    img {
      width: 8px;
      height: 12px;
      display: block;
      margin-right: 10px;
    }
  }
  .detail-card {
    margin-bottom: 20px;
  }
  .card-bot {
    display: flex;
    flex-flow: row wrap;
    .bot-ti {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      display: inline-block;
      margin-bottom: 16px;
    }
  }
  .tables {
  padding: 20px;
}
  </style>
  