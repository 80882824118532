// 列表
export const columnsAll = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
        prop: 'number',
        label: '单号',
        hiddenSearch: true,
        sortable: false,
        width: 160
      },
      {
        prop: 'businessType',
        label: '业务类型',
        hiddenSearch: true,
        sortable: false,
        slotName: 'inspectionType'
      },
    {
      prop: 'status',
      label: '状态',
      hiddenSearch: true,
      sortable: false,
      slotName: 'status',
      width: 120
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      prop: 'materialsSpecifications',
      label: '物料规格',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'defectQuantity',
      label: '不良数量',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'handleTime',
      label: '处理时间',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      prop: 'creator',
      label: '创建人',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'createTime',
      label: '创建日期',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      prop: 'projectName',
      label: '项目',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'sourceNumber',
      label: '源单号',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      sortable: false,
      hiddenSearch: true,
      width: 120
    }
  ]
// 详情页列表
export const columnsDetail = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
{
    prop: 'quantity',
    label: '数量',
    hiddenSearch: true,
    sortable: false
},
{
    prop: 'judgmentType',
    label: '判定类型',
    hiddenSearch: true,
    slotName:'judgmentType',
    sortable: false
},
{
    prop: 'supplierName',
    label: '供应商',
    hiddenSearch: true,
    sortable: false
}
]

