<template>
  <div class="searchs">
    <!-- 顶部折叠搜索 -->
    <el-row>
      <el-col :md="24" :gutter="10">
        <el-row :gutter="10" type="flex" justify="start" style="flex-flow:row wrap;">
          <el-col :md="6">
            <SelectMultipleMaterialsNew ref="multipleMaterial" @typeChange="searchData" />
          </el-col>
          <!--<el-col :md="6">-->
          <!--<el-select v-model="searchFormData.workOrderTempo" placeholder="工单进度" clearable @change="searchData">-->
          <!--<el-option-->
          <!--v-for="item in progressStatus"-->
          <!--:key="item.key"-->
          <!--:label="item.name"-->
          <!--:value="item.key"-->
          <!--/>-->
          <!--</el-select>-->
          <!--</el-col>-->

          <el-col :md="6">
            <el-select v-model="searchFormData.status" placeholder="工单状态" clearable @change="searchData">
              <el-option label="未开工" :value="-1" />
              <el-option label="进行中" :value="0" />
              <el-option label="已结案" :value="1" />
              <!--<el-option label="装配完工" :value="2" />-->
              <!--<el-option label="完工" :value="3" />-->
              <!--<el-option label="入库" :value="4" />-->
              <!--<el-option label="冻结" :value="5" />-->
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.type" placeholder="工单类型" clearable @change="searchData">
              <el-option
                v-for="item in workType"
                :key="item.key"
                :label="item.name"
                :value="item.key"
              />
            </el-select>
          </el-col>
          <!--          <el-col :md="6">-->
          <!--            <el-date-picker-->
          <!--              v-model="searchFormData.deliveryTime"-->
          <!--              type="daterange"-->
          <!--              range-separator="至"-->
          <!--              start-placeholder="交期开始时间"-->
          <!--              end-placeholder="结束时间"-->
          <!--              value-format="yyyy-MM-dd HH:mm:ss"-->
          <!--              format="yyyy-MM-dd HH:mm:ss"-->
          <!--              :default-time="['00:00:00', '23:59:59']"-->
          <!--              :picker-options="pickerOptions"-->
          <!--              clearable-->
          <!--              @change="searchData"-->
          <!--            />-->
          <!--          </el-col>-->
          <el-col :md="6">
            <el-date-picker
              v-model="searchFormData.planStartTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="计划开工开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>







          <el-col :md="6">
            <el-select v-model="searchFormData.priority" placeholder="工单优先级" filterable multiple clearable collapse-tags @change="searchData">
              <el-option
                v-for="(item, index) in priorityStatus"
                :key="index"
                :label="item.name"
                :value="index"
              />
            </el-select>
          </el-col>
          <el-col  :md="6">
            <el-date-picker
              v-model="searchFormData.planEndTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="计划完工开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
          <el-col  :md="6">
            <el-select v-model="searchFormData.processFlowId" placeholder="工艺" clearable @change="searchData">
              <el-option
                v-for="item in processFlowList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                @click.native="selectFlower(item)"
              />
            </el-select>
          </el-col>
          <el-col  :md="6">
            <el-select v-model="searchFormData.processFlowVersionId" placeholder="工艺版本" clearable @change="searchData">
              <el-option
                v-for="item in versionList"
                :key="item.id"
                :label="item.version"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <!-- <el-col :md="6">
            <el-select v-model="searchFormData.areaId" placeholder="产线" clearable @change="searchData">
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col> -->
          <!--<el-col :md="6">-->
          <!--<el-select v-model="searchFormData.businessUnits" multiple placeholder="业务部门" clearable @change="searchData">-->
          <!--<el-option-->
          <!--v-for="item in divisionOptions"-->
          <!--:key="item.key"-->
          <!--:label="item.name"-->
          <!--:value="item.key"-->
          <!--/>-->
          <!--</el-select>-->
          <!--</el-col>-->

        </el-row>
        <el-row type="flex" class="row-bg" justify="end" style="padding-bottom: 10px;justify-content: flex-end">
          <el-col :gutter="10" :span="6" class="all-btns">
            <el-button size="small" type="primary" @click="searchData">查询</el-button>
            <el-button size="small" @click="resetData">重置</el-button>
            <!--            <div class="showMore" :style="{background: show ? '' : '#F8F8F8'}" @click="changeMore">-->
            <!--              <span>高级筛选</span>-->
            <!--              <i v-if="show" class="el-icon-caret-bottom icons" />-->
            <!--              <i v-else class="el-icon-caret-top" />-->
            <!--            </div>-->
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!--    <el-row type="flex" justify="end" style="justify-content: flex-end;margin-bottom: 15px">-->
    <!--      -->
    <!--    </el-row>-->
  </div>
</template>
<script>
import { preparationStatus, planSource, noPlanStatus, havePlanStatus, productionType, productionStatus, procedureClass, isDelay } from '@/config/options.config'
import dayjs from 'dayjs'
import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'
import { divisionOptions,progressStatus,workType } from './data'

export default {
  components: { SelectMultipleMaterialsNew },
  props: {
    isHave: {
      type: Boolean,
      default: false
    },
    formOption: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      divisionOptions,
      progressStatus,
      preparationStatus,
      workType,
      isDelay,
      procedureClass,
      planSource,
      noPlanStatus,
      havePlanStatus,
      planStatuss: [],
      productionType,
      productionStatus,
      show: true,
      processFlowList: [],
      areaList: [],
      versionList: [],
      searchFormData: {
        deliveryTime: [],
        priority: [],
        processFlowVersionId: '',
        processFlowId: '',
        areaId: '',
        planStartTime: [],
        planEndTime: [],
        businessUnits: [],
        workOrderTempo:'',
        type:'',
        status: null
      },
      isShows: true,
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = dayjs().format('YYYY-MM-DD 23:59:59')
            const start = dayjs().format('YYYY-MM-DD 00:00:00')
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end])
          }
        },
          {
            text: '昨天',
            onClick(picker) {
              const end = dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
              const start = dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
      }

    }
  },
  computed: {
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  watch: {
    isHave(val) {
      if (val) {
        this.planStatuss = this.noPlanStatus
        this.isShows = false
      } else {
        this.planStatuss = this.havePlanStatus
        this.isShows = true
      }
      this.versionList = []
    }
  },
  mounted() {
    this.processFlowList = this.formOption.flowerOptions
    this.areaList = this.formOption.areaOptions
    this.planStatuss = this.havePlanStatus
  },
  methods: {
    selectFlower(row) {
      this.versionList = row.versionList
      this.searchFormData.processFlowVersionId = ''
    },
    changeMore() {
      this.show = !this.show
    },
    // 顶部查询
    searchData() {
      const materialsId = this.$refs.multipleMaterial.keyword.id
      this.searchFormData.materialsId = materialsId
      this.$emit('searchData', this.searchFormData)
    },
    // 顶部重置
    resetData() {
      this.searchFormData = {
        deliveryTime: [],
        priority: [],
        processFlowVersionId: '',
        processFlowId: '',
        areaId: '',
        planStartTime: [],
        planEndTime: []
      }
      this.$refs.multipleMaterial.keyword = ''
      this.$emit('resetData')
    }
  }
}
</script>

<style scoped lang="scss">
.searchs {
  padding-top: 10px;
  ::v-deep {
    .el-col-md-6 {
      margin-bottom: 10px;
    }
    .el-date-editor--datetimerange.el-input,
    .el-date-editor--datetimerange.el-input__inner {
      width: 100%;
      height: 32px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
      height: 32px;
    }
    .el-select {
      width: 100%;
    }
    .el-input--suffix .el-input__inner {
      height: 32px;
    }
    .el-button-group .el-button {
      height: 32px;
    }
    .el-date-editor .el-range-separator,
    .el-date-editor .el-range__icon {
      line-height: 27px;
    }
  }
  .showMore {
    width: 88px;
    height: 40px;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #607fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    .icons {
      color: #dde3eb;
    }
    &:hover {
      background: #f8f8f8;
      i {
        color: #607fff;
      }
    }
  }
  .all-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    ::v-deep {
      .el-button {
        height: 32px;
        line-height: 4px;
      }
    }
  }
}
</style>
