var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderAdd" }, [
    _c("div", { staticClass: "procedure-detail-header" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "DefectiveProductJudgeList" })
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c("span", [_vm._v("不良品判定详情")])
        ]
      )
    ]),
    _c("div", { staticClass: "detail-card" }, [
      _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
      _c(
        "div",
        { staticClass: "card-bot" },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v("单号：" + _vm._s(_vm.detailData.number))
            ])
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v(
                "业务类型：" +
                  _vm._s(
                    _vm.inspectionTypeNames[_vm.detailData.businessType - 1]
                  )
              )
            ])
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v(
                "状态：" + _vm._s(_vm.allStatus[_vm.detailData.status - 1])
              )
            ])
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v("物料编码：" + _vm._s(_vm.detailData.materialsCode))
            ])
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v("物料名称：" + _vm._s(_vm.detailData.materialsName))
            ])
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v(
                "物料规格：" + _vm._s(_vm.detailData.materialsSpecifications)
              )
            ])
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v("不良数量：" + _vm._s(_vm.detailData.defectQuantity))
            ])
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v("处理时间：" + _vm._s(_vm.detailData.handleTime))
            ])
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v("创建人：" + _vm._s(_vm.detailData.creator))
            ])
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v("创建日期：" + _vm._s(_vm.detailData.createTime))
            ])
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v("项目：" + _vm._s(_vm.detailData.projectName))
            ])
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v("源单号：" + _vm._s(_vm.detailData.sourceNumber))
            ])
          ])
        ],
        1
      )
    ]),
    _c("div", { staticClass: "detail-card" }, [
      _c(
        "div",
        { staticClass: "tables" },
        [
          _c("MTable", {
            ref: "mTable",
            attrs: {
              height: 410,
              columns: _vm.columnsDetail,
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "judgmentType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      _vm._s(
                        row.judgmentType
                          ? _vm.judgmentTypeList[row.judgmentType - 1]
                          : "-"
                      )
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }