var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fault-wrap common-tab" }, [
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c("FunctionHeader", {
          staticStyle: { padding: "10px 0 10px 0" },
          attrs: {
            "search-title": "输入单号搜索",
            tags: _vm.functionHeader.tags
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        }),
        _c("MTable", {
          ref: "mTable",
          attrs: {
            columns: _vm.columnsAll,
            height: _vm.height,
            "set-data-method": _vm.getTableData
          },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function(ref) {
                var $index = ref.$index
                return _c("div", {}, [_vm._v(_vm._s($index + 1))])
              }
            },
            {
              key: "inspectionType",
              fn: function(ref) {
                var row = ref.row
                return _c("div", {}, [
                  _vm._v(_vm._s(_vm.inspectionTypeNames[row.businessType - 1]))
                ])
              }
            },
            {
              key: "status",
              fn: function(ref) {
                var row = ref.row
                return _c("div", {}, [
                  _vm._v(" " + _vm._s(_vm.allStatus[row.status - 1]) + " ")
                ])
              }
            },
            {
              key: "action",
              fn: function(ref) {
                var row = ref.row
                return _c(
                  "div",
                  { staticStyle: { "z-index": "100" } },
                  [
                    _vm.permission("Detail")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.viewDetail(row.id)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }