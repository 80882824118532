<template>
  <div class="orderAdd">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.push({ name: 'QualityTaskList' })">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>质检任务详情</span>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-col :span="6">
          <span class="bot-ti">任务编号：{{  detailData.code }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">业务类型：{{ inspectionTypeNames[detailData.type] }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">质检方案：{{ detailData.inspectionSchemeName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">质检状态：{{ detailData.status || detailData.status==0 ? qualityTaskStatus[detailData.status].name:'' }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">生产工单：{{ detailData.producePlanNumber }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">生产产品：{{ detailData.materialsFullName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">生产任务：{{ detailData.productionTaskCode }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">工序：{{ detailData.procedureName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">创建人：{{ detailData.createUser }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">创建时间：{{ detailData.createTime }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">任务开始时间：{{ detailData.startTime }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">任务结束时间：{{ detailData.endTime }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">执行人：{{ detailData.executorName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">结论判定：
            <span :style="{color:detailData.result == 0 ? '#E02020':detailData.result == 1 ? '#00AB29':''}">
              {{ detailData.result == 0 ?'不合格':detailData.result == 1 ? '合格':'' }}
            </span>
          </span>
        </el-col>
        <!-- 仅在结论判定未不合格时显示 -->
        <el-col :span="6" v-if="detailData.result == 0">
          <span class="bot-ti">处理意见：{{ detailData.suggestion }}</span>
        </el-col>
      </div>
    </div>
    <div class="detail-card" >
            <el-tabs v-model="activeTabName" class="detail-tabs" type="border-card" >
              <el-tab-pane label="按检验对象" name="按检验对象">
      <div class="tables">
          <div v-if="projectLength > 0">
              <div style="font-size:14px;padding-bottom:10px;">
                  <i class="el-icon-arrow-left" :style="{color:currentPage == 1 ?'#C0C4CC':'#303133',cursor:currentPage === 1 ? 'not-allowed':'pointer'}" @click="beforePage()"></i>
                      <span style="display:inline-block;width:35px;text-align:center">{{ this.currentPage }}</span>
                  <i class="el-icon-arrow-right" :style="{color:currentPage == projectLength ?'#C0C4CC':'#303133',cursor:currentPage == projectLength ? 'not-allowed':'pointer'}" @click="nextPage()"></i>
                  <span style="color:#6B8AFF">
                      第{{currentPage}}份
                  </span>
                  <span style="color:#909399">/共{{projectLength}}份</span>
              </div>
          </div>
        <MTable
          ref="mTable"
          :show-page="false"
          :height="410"
          :columns="columnsDetail"
          :data="productList"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="attachments" slot-scope="{ row }">
              <div v-if="row.attachments" style="color:#6B8AFF;cursor: pointer;" @click="viewPicture(row)">查看</div>
              <div v-else>-</div>
          </div>
          <div slot="measuredValue" slot-scope="{ row }">
            <span :style="{ color:fixData(row) ? '#E02020':'' }">{{row | fixValue}}</span>
          </div>
          <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
        </MTable>
      </div>
      </el-tab-pane>
      <el-tab-pane label="按检验项" name="按检验项">
      <div class="m-table">
        <el-table
      :data="tableData"
      style="width: 100%;height:430px"
      :row-style="{ height: `${customTableRowHeight}px` }">
        <el-table-column
          align="center"
          label="检验组">
          <el-table-column
            prop="partSort"
            label="检验对象\检验项"
            width="150">
            <template slot-scope="scope">
            {{ scope.row.partSort + 1 }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" :label="key" v-for="(value, key) in tableHeader" :key="key">
          <el-table-column
          v-for="item in value" :key="item.id" minWidth="120">
            <template slot="header">
              <span style="padding:6px;">{{ item.inspectionItemName }}</span>
              <el-tooltip class="item" effect="dark" placement="top-start">
                  <div slot="content">
                      <div>检验方法：{{ item.inspectionMethod }}</div>
                      <div>检验标准：{{ item.inspectionStandard }}</div>
                      <div v-if="item.parameterType === 0">标准值：{{ item.standardValue }}</div>
                  </div>
                  <i class="el-icon-question"></i>
              </el-tooltip>
          </template>

            <template slot-scope="scope">
            {{ fixResult(scope.row.result, item) }}
            </template>

          </el-table-column>
        </el-table-column>
        <el-table-column
        prop="updateBy"
        label="更新人"
        width="150">
      </el-table-column>
      <el-table-column
        prop="updateTime"
        label="更新时间"
        width="180">
      </el-table-column>
    </el-table>
      </div>
      </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 工艺路线 -->
    <MDialog v-model="pictureVisible" title="现场图片" width="600px" height="500px" :show-footer="false" @onOk="pictureVisible = false">
      <el-image
        v-for="item in formatArr(imgList)"
        :key="item"
        style="padding-left: 10px"
        :src="item"
        class="table-row-img"
        :preview-src-list="formatArr(imgList)"
      />
    </MDialog>
    <MDialog v-model="processVisible" title="工艺路线" :show-footer="false" @onOk="processVisible = false">
      <single-process-flow ref="processFlow" :current-procedure-names="currentProcedureName" :read-only="!saveButton" :all-work-order-task-list="allWorkTask ? allWorkTask : []" />
    </MDialog>
    <!-- 物料详情-->
    <edit-materials ref="editMaterials" />
    <!-- 选择工单 -->
    <select-work-order
      ref="selectWorkOrder"
      :visible.sync="showWorkOrder"
      :produce-order-id="id"
      @submitForm="submitWorkOrder"
    />
    <!-- 选择计划 -->
    <select-plan-order
      ref="selectPlanOrder"
      :visible.sync="showPlanOrder"
      :produce-order-id="id"
      @submitForm="submitPlanOrder"
    />
  </div>
</template>
<script>
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import { columnsDetail } from './columns'
import { planStatus, orderSource, productionStatus,qualityTaskStatus } from '@/config/options.config'
//   import api from '@/api/production/orders'
import workApi from '@/api/production/work-order'
import planApi from '@/api/production/plans'
import processApi from '@/api/information/production/process'
import FunctionHeader from '@/components/FunctionHeader/index'
import { Encrypt } from '@/utils/sercet'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import SelectWorkOrder from '@/components/SelectWorkOrder/SelectWorkOrder'
import SelectPlanOrder from '@/components/SelectPlanOrder/SelectPlanOrder'
import saveAs from 'file-saver'
import singleProcessFlow from '@/components/singleProcessFlow/singleProcessFlow'
import api from '@/api/qualityManagement/quality-task'

export default {
  name: 'QualityTaskDetail1',
  components: { singleProcessFlow, FunctionHeader, ProcessFlow, EditMaterials, SelectWorkOrder, SelectPlanOrder },
  mixins: [viewMaterialsDetail],
  filters: {
        fixValue(val) {
            if (val) {
              if(val.parameterType === 4){
                   return val.measuredValue == 1 ? '合格': val.measuredValue == 0 ? '不合格' :'-'
              }else{
                return val.measuredValue ? val.measuredValue:'-'
              }
        }
   },
  },
  data() {
    return {
      columnsDetail,
      planStatus,
      orderSource,
      productionStatus,
      allWorkTask: [],
      userList: [],
      customTag: [],
      qualityTaskStatus,
      showPlanOrder: false,
      showWorkOrder: false,
      show: false,
      orderList: [],
      detailData: {
        source: 0
      },
      imgList:[],
      pictureVisible:false,
      productList: [],
      inspectionTypeNames: ['首检', '过程检', '来料检', '出货检'],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('AddWorkOrder'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '关联工单',
            on: {
              click: this.addWorkOrder
            }
          }
        ]
      },
      functionHeaders: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('AddPlanOrder'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '关联计划',
            on: {
              click: this.addPlanOrder
            }
          }
        ]
      },
      activeName: 'first',
      relationType: 0,
      id: undefined,
      processVisible: false,
      saveButton: false,
      processFlowVersion: {},
      currentProcedureIds: [],
      currentProcedureName: '',
      currentPage:1,
      projectLength:0,
      activeTabName: '按检验对象',
      tableData: [],
      tableHeader:{}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 40 - 54 + 29 - 62 - 22
      // :height="$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - 192"
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    customTableRowHeight() {
      return this.$store.state.app.tableRowHeight
    }
  },
  activated() {
    // this.id = parseInt(this.$route.query.id)
    // this.getOrderDetail(this.$route.query.id)
  },
  mounted(){
      this.getOrderDetail(this.$route.query.id)
  },
  methods: {

   fixResult(val,val2){
      let currentData = val.find((item) => item.inspectionGroupName === val2.inspectionGroupName && item.inspectionItemName === val2.inspectionItemName).measuredValue
      return currentData || currentData === 0  ? currentData : '-'
},
    fixData(row){
       if(row.parameterType === 4){
          return row.measuredValue == 0 ? true:false
       }else if(row.parameterType === 0){
        return (row.standardValue != null && row.measuredValue) ? row.measuredValue > (row.upperLimit + row.standardValue) || row.measuredValue < (row.standardValue - row.lowerLimit) ? true : '' : ''
       }else{
        return false
       }
    },
    formatArr(arr) {
    try {
      return JSON.parse(arr).map(item =>{
        return item.url
      })
    } catch (err) {
      return []
    }
  },
      beforePage(){
          if(this.currentPage>1){
              this.currentPage--
              this.getOrderDetail(this.$route.query.id)
          }
      },
      nextPage(){
          if(this.currentPage<this.projectLength){
              this.currentPage++
              this.getOrderDetail(this.$route.query.id)
          }
      },
      viewPicture(row){
        this.imgList = row.attachments
        this.pictureVisible = true
      },
    async getProduceProcessList(row) {
      const res = await workApi.getProduceProcessList({
        id: row.id
      })
      if (res) {
        this.allWorkTask = res
      }
    },
    fixImg(img) {
      if (img) {
        return [img]
      }
    },
    handlePreview(file) {
      saveAs(file.url, file.name)
    },
    fixArrs(val) {
      return val ? JSON.parse(val) : ''
    },
    async submitPlanOrder(val) {
      this.showPlanOrder = false
      const ids = val.map(item => item.id)
      const res = await api.addPlanOrder({ id: this.id, workOrderIdList: ids })
      if (res) {
        this.$refs.orderTables.setTableData()
      }
    },
    async submitWorkOrder(val) {
      this.orderList = val
      this.showWorkOrder = false
      const ids = val.map(item => item.id)
      const res = await workApi.addWorkOrder({ id: this.id, workOrderIdList: ids })
      if (res) {
        this.$refs.orderTable.setTableData()
      }
    },
    async cancelWorkOrders(row) {
      this.$confirm('您确定要取消关联吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await workApi.cancelWorkOrder({ id: this.id, workOrderIdList: [row.id] })
        if (res) {
          this.$refs.orderTable.setTableData()
        }
      })
    },
    async cancelPlanOrders(row) {
      this.$confirm('您确定要取消关联吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.cancelPlanOrder({ id: this.id, workOrderIdList: [row.id] })
        if (res) {
          this.$refs.orderTables.setTableData()
        }
      })
    },
    async showProcess(row) {
      // await this.getProcessDetailById(row.processFlowId, row.processFlowVersionId)
      await this.getProduceProcessList(row)
      this.currentProcedureName = row.currentProcedureName
      this.processVisible = true
    },
    // 获取工艺详情
    async getProcessDetailById(id, versionId) {
      const res = await processApi.getProcessDetail({ id: id, versionId: versionId })
      if (res) {
        this.processFlowVersion = res.processFlowVersion
      }
    },
    fixProduceData(val) {
      const list = val.map(item => item.procedureName)
      return list.lenght === 1 ? list[0] : list.join('-')
    },
    jumpOrderDetail(row) {
      this.$router.push({ name: 'orderDetail', query: { id: row.produceOrderId }})
    },
    jumpDetail(row) {
      this.$router.push({ name: 'workDetail', query: { id: row.id }})
    },
    addWorkOrder() {
      // this.selectIds = this._.cloneDeep(this.orderList)
      this.showWorkOrder = true
    },
    addPlanOrder() {
      this.showPlanOrder = true
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const res = await workApi.getPlanpage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        produceOrderId: this.id,
        relationType: this.relationType,
        searchVal: Encrypt(JSON.stringify([...search]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    // 计划列表
    async getPlanTableData(condition, callback) {
      const { page, search, order } = condition
      const res = await planApi.getPlanpage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        produceOrderId: this.id,
        relationType: this.relationType,
        searchKeyword: this.functionHeaders.searchVal,
        searchVal: Encrypt(JSON.stringify([...search]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    // 订单详情
    async getOrderDetail(id) {
      // this.productList = [{materialsPicture:'https://oss.mubyte.cn/mes2-alpha/mubai/2023/06/28/16879469012003553.jpg'},{materialsPicture:''}]
      this.productList = []
      const res = await api.getQualityTaskDetail({inspectionTaskId:id})
      if (res) {
         this.projectLength = Object.keys(res.itemList).length
        this.detailData = res
        this.productList = res.itemList[this.currentPage -1]
        this.tableHeader = res.tableHead
        this.tableData = res.tableEntry
      }
    },
    returnList() {
      this.$router.push({ path: 'order-list' })
    }
  }
}
</script>

<style scoped lang="scss">
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
::v-deep {
.table-row-img {
  >img {
    width: 100px;
  }
}
}
.bot-li {
  display: flex;
  justify-content: flex-start;
  .bot-ti {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    display: inline-block;
    margin-bottom: 15px;
  }
}
.el-tags {
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.icon-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 19px;
  height: 19px;
  display: block;
}
.all-ipt {
  margin-top: 4px;
}
.procedure-detail-header {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456b;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  img {
    width: 8px;
    height: 12px;
    display: block;
    margin-right: 10px;
  }
}
.detail-card {
  margin-bottom: 20px;
}
.card-bot {
  display: flex;
  flex-flow: row wrap;
  .bot-ti {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    display: inline-block;
    margin-bottom: 16px;
  }
}
</style>
